<script setup lang="ts">
  import {useI18n} from 'vue-i18n';
  import CpText from '~/components/cp/CpText/CpText.vue';
  import useCpBem from '~/composables/useCpBem';
  import CpButton from '~/components/cp/CpButton/CpButton.vue';
  import CpProductRate from '~/components/cp/CpProductRate/CpProductRate.vue';
  import CpLogo from '~/components/cp/CpLogo/CpLogo.vue';
  import {downloadApp, getReviewsData} from '~/utils';

  const emit = defineEmits(['notificationBannerEvent']);

  const {b, e} = useCpBem('cp-notification-banner-promote-app');
  const {t} = useI18n();
  const reviewsData = getReviewsData();
</script>

<template>
    <div :class="b">
        <div :class="e('logo')">
            <CpLogo imago-type :primary="false" />
        </div>

        <div :class="e('main-container')">
            <CpText variant="body-regular" :class="e('download-text')">
                {{ t('downloadNow') }}
            </CpText>
            <CpProductRate :rate="reviewsData.rate" hide-count />
        </div>
        <CpButton
            :label="t('download')"
            type="button"
            kind="label"
            @click="downloadApp()"
        />
    </div>
</template>

<style scoped lang="scss">
  .cp-notification-banner-promote-app {
    border-bottom: $cp-border-standard;
    padding: $cp-gutter;
    display: flex;
    flex-direction: row;
    gap: $cp-gutter;
    width: 100%;
    align-items: center;
    &__main-container {
      width: 100%;
      line-height: 0;
      display: flex;
      flex-direction: column;
      gap: $cp-gutter-small;
    }
    &__logo {
      padding: $cp-gutter-small;
      border-radius: 5.33px;
      background-color: var(--color-primary);
      width: 40px !important;
      height: 40px !important;
    }
  }
</style>
<i18n locale="es-US">
  download: 'Descargar'
  downloadNow: '¡Descárgala ahora, regístrate y obtén $100 de regalo!'
</i18n>
